<template>
  <div class="bottom-right-phone-icon" v-if="isOdysseyAgent && isStaging && device === 'desktop'">
    <a href="#" @click="showModal"><img :src="phoneImage" class="img-call" alt="phone"/></a>
    <caller-info-modal/>
  </div>
  <!-- <div class="bottom-right-phone-icon" v-else-if="!isOdysseyAgent && device==='mobile'">
    <a href="https://wa.me/972503112787?text=היי, אשמח לקבל שרות דרך הווצאפ" target="_blank"><img src="assets/img/whatsapp-icon.png" class="img-call" alt="whatsapp"/></a>
  </div> -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BottomRightCall',
  components: {
    CallerInfoModal: () => import('./CallerInfoModal'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isSocketConnected: 'IS_SOCKET_CONNECTED',
      isOdysseyAgent: 'GET_ODYSSEY_AGENT_STATE',
      isStaging: 'GET_IS_STAGING_MODE',
    }),
    phoneImage() {
      const { isSocketConnected } = this;
      const root = this.$route.name;
      let path = '';
      if (root !== '') {
        path = '../';
      }
      const fileName = isSocketConnected ? 'callerInfo' : 'caller-disconnect';
      return `${path}assets/img/${fileName}.png`;
    },
  },
  watch: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    showModal() {
      if (!this.isSocketConnected) return;
      this.$modal.show('caller-info');
    },
  },
};
</script>
<style scoped>
  .bottom-right-phone-icon{
    position: fixed;
    bottom: 2px;
    right: 2px;
    z-index: 100;
    display: flex;
    flex-direction: row-reverse;
  }
  .img-call{
    width: 50px;
  }

@media (max-width: 767px) {
  .bottom-right-phone-icon{
    transform: translateZ(0);
    will-change: transform;
  }

}
</style>

<style>
</style>
